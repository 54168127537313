import { ethers } from "ethers";
import arbitrum_logo from "./assets/svg/arbitrum_plain.svg";
import polygon_matic from "./assets/svg/polygon_matic.svg"
import ethereum_logo from './assets/svg/ethereum_plain.svg';

import { EnvHelper } from "./Environment";
import { StaticJsonRpcProvider } from "@ethersproject/providers";
import { LogToConsole, LogToConsoleError } from "./helpers/Logger";
import { CATEGORIES } from "./enums/offers.enum";

export const GetDefaultLocation: any = {
  Lat: 40.718848,
  Long: -74.012298
}

export enum NetworkId {
  ARBITRUMTEST = 421613,
  ARBITRUM_ONE = 42161,
  POLYGON_MUMBAI_TESTNET = 80001,
  POLYGON_MAINNET = 137,
  CRONOS_TESTNET = 338,
  CRONOS_MAINNET = 25,
  GOERLI = 5,
  MAINNET = 1,
  SHIB_TESTNET = 719,
  SHIB_MAINNET = 109,
  ARBITRUM_SEPOLIA = 421614,
  ETHEREUM_SEPOLIA = 11155111,
  ARBITRUM_NOVA = 42170,
  ARBITRUM_NOVA_FORK = 42170999

}

export const REDIRECT_URL = EnvHelper.env.REACT_APP_REDIRECT_URL;
export const MASTER_KEY = EnvHelper.env.REACT_APP_MASTER_KEY;
export const IPNS_UPLOAD_LAMBDA_URL = EnvHelper.env.REACT_APP_IPNS_UPLOAD_LAMBDA_URL;
export const SOCIAL_MEDIA_API = EnvHelper.env.REACT_APP_SOCIAL_MEDIA_API;
export const REACT_APP_SOCIAL_ACCOUNT_VERIFY_API = EnvHelper.env.REACT_APP_SOCIAL_ACCOUNT_VERIFY_API;

export const APP_CONFIGURATION_FILE = "appconfig.json";
export const FRIENDS_IPNS_FILE_NAME = 'friends.json';
export const USERDETAIL_IPNS_FILE_NAME = 'userdetail.json';
export const ZERO_ADDRESS = ethers.constants.AddressZero;
export const THUMBNAIL_UPLOAD_SIZE = 1024 * 1024; // 1 MB
export const IMAGE_UPLOAD_SIZE = 2 * 1024 * 1024; // 2 MB
export const IMAGE_UPLOAD_LIMIT = 100 * 1024 * 1024; // 100 MB
export const SMALL_IMAGE_SIZE_LIMIT = 4 * 1024 * 1024; // 4 MB
export const IMAGE_REGEX = new RegExp(/^image\/\w/);
export const VIDEO_REGEX = new RegExp(/^video\/\w/);
export const ADDRESS_REGEX = new RegExp(/^0x[a-fA-F0-9]{40}/);
export const isProduction = EnvHelper.env.REACT_APP_ENV === "production";
export const isStaging = EnvHelper.env.REACT_APP_ENV == "staging"
export const WEB_URL = isProduction ? "https://app.loot8.io/" : isStaging ? "https://staging.loot8.io/" : "https://app.loot8.dev/"
export const BILLBOARD_SCRIPT =
  isProduction ?
    "https://ipfs-prod-01.loot8-services.dev:443/ipns/k51qzi5uqu5dj3lp9vo0dbmnfro045ej5e6w4pvokto2ghkr0h9a01nc818qq6/script.js"
    :
    "https://loot8-cdn-dev.loot8.io/ipns/k51qzi5uqu5dlqhlias6mtzusn62o2wnvmuuooi2248zna4mhcbz5ymvsad6sk/script.js"

export const TWITCH_LOGIN_VERIFIER_NAME = 'loot8-custom-auth-twitch';
export const TWITCH_CLIENT_ID = 'ujxg80skt89ijb4r699kju59eb21f5';

let _ipfs_url = EnvHelper.env.REACT_APP_IPFS_URL;
let _ipns_url = EnvHelper.env.REACT_APP_IPNS_URL;
let _api_request_timeout = EnvHelper.env.REACT_APP_REQUEST_TIMEOUT;
let loggerEnabled = EnvHelper.env.REACT_APP_LOGGER_ENABLED;
let _relayerInfo = null;

export const getMaintenanceInfo = async () => {
  try {
    const response = await fetch(
      EnvHelper.env.REACT_APP_IPNS_UPLOAD_LAMBDA_URL + "status",
      { method: "POST" }
    )
    if (response.status === 200) {
      const res = await response.json()
      return {
        inMaintenance: res.inMaintenance,
        maintenanceInfo: res.maintenanceInfo
      }
    }
  } catch (error) {
    console.error("getMaintenanceInfo Error: ", error)
    return {
      inMaintenance: false,
      maintenanceInfo: ''
    }
  }
}


export const INDEXER_SERVICE_URL = EnvHelper.env.REACT_APP_INDEXER_SERVICE_URL;

export const IsLoggerEnabled = () => {
  return (!isProduction || loggerEnabled);
}

export const IPFS_URL = () => {
  return _ipfs_url;
}

export const IPNS_URL = () => {
  return _ipns_url;
}

export const API_REQUEST_TIMEOUT = () => {
  return _api_request_timeout;
}

interface IAddresses {
  [key: number]: { [key: string]: string };
}


export const addresses: IAddresses = {
  [NetworkId.ARBITRUMTEST]: {
    CollectionFactory: "0xE819FaC026621fDff989A507fE38fd4426C9F3E8",
    CollectionHelper: "0xcBE440f712b63975a54006F0C4d588a034fa2519", // private forwarder
    CollectionManager: "0xcBE440f712b63975a54006F0C4d588a034fa2519", // private forwarder
    DAOAuthority: "0xB7433289940A0D942B37FC060fd1815C29A79d17",
    DAOERC20: "0x7729e37190a30eC529bbebD8c181Ea2797ea76c3",
    Dispatcher: "0x7cCb86A354A1f553F27B34c4b6c6E34f99259808",
    DispatcherHelper: "0x3077e62083882427476B06c91b276f1814Bf431c",
    EntityRegistry: "0xc91B0d95079C931B6174a5ff394179BDD9325586",
    ExternalCollectionManager: "0xCB21Eb65723050841B8FFa849c098687de175bFA",
    LayerZeroEndPoint: "0x6aB5Ae6822647046626e83ee6dB8187151E1d5ab",
    Onboarder: "0xa41B2a85DDEfFbC538651108916623647E1dD0c2",
    POAPCollectionFactory: "0x4b3a383A13390AB5CA66f7F6Ee273FAbE3De9ED1",
    PrivateForwarder: "0xcBE440f712b63975a54006F0C4d588a034fa2519",
    RelayerAddress: "0xaFdC3e383B89DEcF9c4B928183D9d8865A844412",
    SubscriptionManager: "0xc6Bd70136f89EEedDa7EB150838419e60632FBf3",
    TieredCouponCollectionFactory: "0xD26D7C1E73692E28a182044ea602774a8617AFC4",
    TokenPriceCalculator: "0x4534c0c01Ecb75c409f492965406D2D87249D28C",
    User: "0xe4DD7a2ba119c5D6078a8Fcf496043A74F5f1406",
  },
  [NetworkId.ARBITRUM_SEPOLIA]: {
      CollectionFactory: "0x63Cc63B2AE79AE58EacFc54D03a7496C88D9643C",
      CollectionHelper: "0x0f2ffdEACe3Df90f7b128DC7D9B3ff3b7342F219",
      CollectionManager: "0x33e5a1FEc542d8b7E829521FBf514f197937b322",
      DAOAuthority: "0x9d2a5AE0289C57DEf7390B723cFB03ffc55380D5",
      DAOERC20: "0xA0109145440922d326ad6Ba96e74F4778DB0B128",
      Dispatcher: "0x071047E8A0f2eb1891a78A89459Afe9850B45d84",
      DispatcherHelper: "0x673238Af161AAf7FbD87fAC4904461c22C544B38",
      Entity: "0x999b6c00B05BB73C83CB61BA043D303BDb357a0D",
      EntityFactory: "0xc5C5695D1083C29b5E4cD1aCA9Aa6128B49794ba",
      EntityRegistry: "0x5fbaBE8E17B1eC0ADF4AB1ec6a360cAD87E18d6F",
      ExternalCollectionManager: "0x4e59CB2A460C801887C2D8082102642466c67471",
      LayerZeroEndPoint: "0xae92d5aD7583AD66E49A0c67BAd18F6ba52dDDc1",
      Onboarder: "0x353D1D35cdCaF2DA5C75B39c8f154A51DF138096",
      POAPCollectionFactory: "0xf0903aaF520C3d4c4cC6fa5C65cbB937BC7702B2",
      PrivateForwarder: "0x0fC89252F0994870eD41af3dE2EEaFBAc69D7063",
      Loot8Forwarder: '0xF7932eF5c52b6fDae09EB557480C4CceA3D9888e', // New Forwarder Contract
      RelayerAddress: "0x80cC5Cc40aF77054D132869C1655661D7bE06ab1",
      SubscriptionManager: "0x76318bd75D56525c7B9728F12C58D37c4F893F3c",
      TieredCouponCollectionFactory: "0xC37679fCb9571d90110AFFC59d384048A9814cb4",
      TokenPriceCalculator: "0x97D8970F1046745e4814f373Ceb8C1d4eD50705F",
      User: "0x51b8EEC06ae1A04fF8843674ECb946F5C736C502",
      Event:"0xe4710D30372D685846bf9a1C7b81aC755bEe3f45",
      EventFactory:"0x47F2ef40CB85653f1b0d89328C1f34992506e12C",
      EventRegistry:"0xa1F20515631040824f5017Ed9bB13e84076Ae67c",
      Ticket:"0xe69eB5FD4f92a45396cd8a1d8CCd1b3111169E66",
      TicketFactory:"0x64D866a28eC21cC34D8153a69dbbb7B2C7Dc53e2",
      Verifier:"0x637d896a3EF2147A26389530Ab26C41712F1FA26"
  },
  [NetworkId.ETHEREUM_SEPOLIA]: {
    CollectionFactory: "0x8F8B1D413A9a2ae920508217C0a250060FdD1b65",
    CollectionHelper: "0x334e121b9f193D57dc252E0ABD708FeB9196d2a6",
    CollectionManager: "0x36075D788bDcb543d1AF8A4d47a8d8F2BE108821",
    DAOAuthority: "0x6818Ee1cC5a6F8DE5cf4E87C83cF394E863F13ad",
    DAOERC20: "0xF95d7589D976dB3E9E17FCF5D01A8f22afc8D973",
    Dispatcher: "0xC044189572aa3207621522F0f56eb590D7249dE9",
    DispatcherHelper: "0xb410F55F4E2A8B6eFd81C3625C41D9a5f10338F9",
    Entity: "0xfbC53423C76c2f4Bb0111A7301d2455B65183E65",
    EntityFactory: "0x078Be426797862c83d80c3756688f1011e5e6781",
    EntityRegistry: "0x86dB18fc4148B189b54838Bf9d1413601FbDeE49",
    ExternalCollectionManager: "0x09d7Ff15fBb8F893A411D187c69587cA01e52799",
    LayerZeroEndPoint: "0x6EDCE65403992e310A62460808c4b910D972f10f",
    Onboarder: "0xaAA49884AFE0032BC8b1d40cdDB17bD627B6F233",
    POAPCollectionFactory: "0xaAe36Fde1e9c9B5999193d083073b06F1E4D3359",
    PrivateForwarder: "0x5aD478855321Ccd2bDc45793628355fb4421aBc2",
    Loot8Forwarder: '0x36749824ED31c9AF0C8CCBb7830C3dc4919FDDcB',
    RelayerAddress: "0x80cC5Cc40aF77054D132869C1655661D7bE06ab1",
    SubscriptionManager: "0x8e00621333E81eAb1e50201c00755003aE2f67E0",
    TieredCouponCollectionFactory: "0x0b943DbcDa8bffCCb829fC1af4aAc8ad38Ffb20a",
    TokenPriceCalculator: "0x2a5ab8D7909Ce4d2749A3E5CBe45D8b3DF4600B7",
    User: "0x8e6DcCf7dE4FC296E65f42Fd9910Ab6C42D33226",
    Event: "0xe4710D30372D685846bf9a1C7b81aC755bEe3f45",
    EventFactory: "0x49479Cb2976B448940F409D076F2588cDdfB6956",
    EventRegistry: "0x0A13Bee4cE1FFa87896D5e5AF1F27F8916D14cde",
    Ticket: "0xe69eB5FD4f92a45396cd8a1d8CCd1b3111169E66",
    TicketFactory: "0x8B7ac6c35B7480b0fc540cbe212fAaE907465f81",
    Verifier: "0x85Bb783DAaD51B0455d4b63a039266dB4196287E"
  },
  [NetworkId.ARBITRUM_ONE]: {
    DAOAuthority: "0xEb66fA49EC9607A4e2dE5172235D675352E54521",
    EntityRegistry: "0x103d356C104048F3F7098dd504b4766f8AdeB59D",
    CollectionFactory: "0x488F1e04529d74F20f6F4a6Ea82C2798F81288C9",
    POAPCollectionFactory: "0xb80C127EFE2222392a649972B1C4D15B7Da64E3b",
    User: "0xb5CD39a04576E53EEC4ba13BeaC7f5c5c01ebF67",
    Dispatcher: "0xa239412E8973549EC1F6B64bC0cEc63ccD450271",
    DispatcherHelper: "0xA8D455bb1F193858D3f5B2481C0f42B3F35C241B",
    DAOERC20: "0xa01598Ced2777958c3Ab1834648771bc67695482",
    CollectionManager: "0x457CA63331523f0966f65B1fBa04a64B8D643837",
    CollectionHelper: "0x08b3Ce83c462347c2CbF64375334cE4762Fc790F",
    ExternalCollectionManager: "0xF0E5fE892442CA6acA31D99408bb5Aba196EBC5c",
    TokenPriceCalculator: "0x91e1Ecdd503802a0a4C198371072940322492b06",
    PrivateForwarder: "0xA0C19aaa2ac7a6e5bF2012F0D66Ea9a266A0788F",
    RelayerAddress: "0xe283A51c57b36Eb462F284d02b6988bfccb2f5B7",
    LayerZeroEndPoint: "0x1a44076050125825900e736c501f859c50fE728c",
    TieredCouponCollectionFactory: "0xf13De8e9ece23f4859A017D5c6BcE9349dDEcafd",
    Onboarder: "0x0F9d7e42bA01B675Fc94849B29b2e21a73F61B70",
    SubscriptionManager: "0x54b0b9504bc5DAac7f59186c678D99A4A71716BB",
    EntityFactory: "0x1812338c36824Ac852c30B9Bad7d661F2cAEFE47",
    Loot8Forwarder: '0xA618602A01E0B545046Bfadee6A0B55d1552B00C',
    EventFactory: "0xdf08509105016cF786F92a330126D50adc2BB623",
    EventRegistry: "0xc90Bc9e8B9a200506c9479DF0573b07Ff6bDC69f",
    TicketFactory: "0xffff928005057a7EDa93c723FF8AF8fbB4df2461",
    Verifier: "0xCae9F5f24540Fb397eC768C19596B6AB942adA91"
  },
  [NetworkId.ARBITRUM_NOVA]: {
    DAOAuthority: "0xEff619A93B05FBD746777C86C74f08FC0FA97D36",
    EntityRegistry: "0xcEFF1F19F6F3B9dA3bce9363D1908dD892dAa9B8",
    CollectionFactory: "0x15b9024F3d36F5Ad6323ef397e9D082E9B03E24C",
    POAPCollectionFactory: "0x3f4214857c56cC524D5fEFA1b2BD0F364E2b4766",
    User: "0xF4C57821b217C7891e820D98688002c6E5983a02",
    Dispatcher: "0x8F39506f9466019F2121fc8b6624AD00153D7F64",
    DispatcherHelper: "0xD3810ebeEfa414a7f14992001623F9B8d47bB1b0",
    DAOERC20: "0xECbCF497Fb0321e2E0CEfa560F3B5ace2cF99b17",
    CollectionManager: "0x7D405530f6de57ae99C0Aef6d7251c404589e25C",
    CollectionHelper: "0x1F75698fA087F77Bd02D2636317EA12F4A74a081",
    ExternalCollectionManager: "0xBFceF9ed665b97723a4a102FDCfF85214611e396",
    TokenPriceCalculator: "0xC91FA4b1d811a58D0952F6C4181d5262cBaE1C07",
    PrivateForwarder: "0x086AB3dD6F57018df7A84818e676c121b3a3aBDd",
    RelayerAddress: "0x2b7388cD2B8Cc25C91368a56F59c07c3FE46DfcA",
    LayerZeroEndPoint: "0x3c2269811836af69497E5F486A85D7316753cf62",
    TieredCouponCollectionFactory: "0x50D99b0bD6407660DA2C79A300cCDE4B533118AB",
    Onboarder: "0xe4cb440c4502e17FF4247977956564d8D3D5c6b6",
    SubscriptionManager: "0x5a782aD9aC18A8d3Db6C98c30ee8f20Ab8Bb00a6",
    Loot8TicketVerification: "0xDb2B506BB32eE6c9cA415630f3bA87767f39f4a8",

    EventFactory:"0x3D2776EE9B110588e3D902fd51636363de9ba11e",
    EventRegistry:"0x29a2e3b9e4e01B411C1c5E35F67bb8C1E45589Ee",
    TicketFactory:"0xa67AEeE85E828590e6c1cF91EbF6C02916c1e848",
  },
  [NetworkId.ARBITRUM_NOVA_FORK]: {
    DAOAuthority: "0xEff619A93B05FBD746777C86C74f08FC0FA97D36",
    EntityRegistry: "0xcEFF1F19F6F3B9dA3bce9363D1908dD892dAa9B8",
    CollectionFactory: "0x15b9024F3d36F5Ad6323ef397e9D082E9B03E24C",
    User: "0xF4C57821b217C7891e820D98688002c6E5983a02",
    DAOERC20: "0xECbCF497Fb0321e2E0CEfa560F3B5ace2cF99b17",
    TokenPriceCalculator: "0xC91FA4b1d811a58D0952F6C4181d5262cBaE1C07",
    Dispatcher: "0x8F39506f9466019F2121fc8b6624AD00153D7F64",
    DispatcherHelper: "0xD3810ebeEfa414a7f14992001623F9B8d47bB1b0",
    CollectionManager: "0x7D405530f6de57ae99C0Aef6d7251c404589e25C",
    CollectionHelper: "0x1F75698fA087F77Bd02D2636317EA12F4A74a081",
    ExternalCollectionManager: "0xBFceF9ed665b97723a4a102FDCfF85214611e396",
    POAPCollectionFactory: "0x3f4214857c56cC524D5fEFA1b2BD0F364E2b4766",
    TieredCouponCollectionFactory: "0x50D99b0bD6407660DA2C79A300cCDE4B533118AB",
    Entity: "0x857F7675755fA1317D1CE015bC3c3780FbFaDb65",
    SubscriptionManager: "0x5a782aD9aC18A8d3Db6C98c30ee8f20Ab8Bb00a6",
    RelayerAddress: "0x80cC5Cc40aF77054D132869C1655661D7bE06ab1",
    Loot8MarketplaceVerification: "0xdf83d639C24076b0FB9362173F64eF95604ba8f7",
    Loot8Marketplace: "0x7634F7315b055A6a39e7f0D55f54BE255f913783",
    Loot8FiatOffRamp: "0x053eFFD341e69592d9e65431aF829779f70E0A89",
    PrivateForwarder: "0x086AB3dD6F57018df7A84818e676c121b3a3aBDd",
    RoyaltyManager: "0x658CC90df7ed0A0975a6C96f32beA3D8cD715a6E",
    Loot8TicketVerification: "0x5804504Dd781937103a9fC76fb00724A28308B8d",
    EventFactory:"0x1373bA32b761f156090817B9F6917d2c7bC17536",
    EventRegistry:"0x20b8704Bd1678b3250e42616D6F05791030b1201",
    TicketFactory:"0xC285496379cFEe5812c1D29495960bc1f2Dd5F16",
    Ticket: "0xe69eB5FD4f92a45396cd8a1d8CCd1b3111169E66",
    LayerZeroEndPoint: "0x3c2269811836af69497E5F486A85D7316753cf62",
    Onboarder: "0xe4cb440c4502e17FF4247977956564d8D3D5c6b6",
  },
  [NetworkId.POLYGON_MUMBAI_TESTNET]: {
    CollectionFactory: "0x2E17c7b53Ab12184bCa8d6A3BbF28D99AB87B7F7",
    DAOAuthority: "0x804191Fc93a6e4A2c4EEF6fC5ed51b9119E53A9F",
    PrivateForwarder: "0x063d056a01d5949EE5288bcB0ac7705beF059DD8",
    RelayerAddress: "0xaFdC3e383B89DEcF9c4B928183D9d8865A844412",
    POAPCollectionFactory: "0xc191243D1dfbB171BE12573a3f22f76FeD8111db",
    LayerZeroEndPoint: "0xf69186dfBa60DdB133E91E9A4B5673624293d8F8",
    CollectionManager: "0x063d056a01d5949EE5288bcB0ac7705beF059DD8",
    CollectionHelper: "0x063d056a01d5949EE5288bcB0ac7705beF059DD8", // forwarder for polygon
    TieredCouponCollectionFactory: "0x80A06458ef273A8f87ab7fd5F066b2eDDC03B27b",

  },
  [NetworkId.POLYGON_MAINNET]: {
    CollectionFactory: "0xCC9601EE5dF9D612fE7E508f222c2b345b1a304d",
    DAOAuthority: "0xcf974945Be43534f599788dDFbca70c566587fE5",
    PrivateForwarder: "0xe0542a0ab39EB36E45a001735f952881AD44511F",
    RelayerAddress: "0x2b7388cD2B8Cc25C91368a56F59c07c3FE46DfcA",
    POAPCollectionFactory: "0xBFceF9ed665b97723a4a102FDCfF85214611e396",
    LayerZeroEndPoint: "0x3c2269811836af69497E5F486A85D7316753cf62",
    CollectionManager: "0xe0542a0ab39EB36E45a001735f952881AD44511F",
    CollectionHelper: "0xe0542a0ab39EB36E45a001735f952881AD44511F", // forwarder for polygon
    TieredCouponCollectionFactory: "0x783Eb4a7bf04763d72694a20A666ADaEA10f9662",

  },

};


export const Categories = [
  {
    label: "other",
    value: "OTHER",
    enum: CATEGORIES.OTHER
  },
  {
    label: "Sports",
    value: "SPORTS",
    enum: CATEGORIES.SPORTS

  },
  {
    label: "Music",
    value: "MUSIC",
    enum: CATEGORIES.MUSIC

  },
  {
    label: "Celebrities",
    value: "CELEBRITIES",
    enum: CATEGORIES.CELEBRITIES

  },
  {
    label: "Educators",
    value: "EDUCATORS",
    enum: CATEGORIES.EDUCATORS

  },
  {
    label: "Business",
    value: "BUSINESS",
    enum: CATEGORIES.BUSINESS

  },
  {
    label: "Gaming",
    value: "GAMING",
    enum: CATEGORIES.GAMING

  },
  {
    label: "Artist",
    value: "ARTIST",
    enum: CATEGORIES.ARTIST

  },
  {
    label: "Foundations",
    value: "FOUNDATIONS",
    enum: CATEGORIES.FOUNDATIONS

  }
]

interface INativeCurrency {
  name: string;
  symbol: string;
  decimals?: number;
}

interface INetwork {
  chainName: string;
  chainId: number;
  nativeCurrency: INativeCurrency;
  rpcUrls: string[];
  blockExplorerUrl: string;
  image: any,//SVGImageElement;
  imageAltText: string;
  uri: () => string;
  isOracleIntegrated?: boolean;
  WEBHOOK_URL: string,
  genesisBlockNumber: number
}


const initAppConfig = () => {
  _app_configuration = {
    nodeProviders,
    logsNodeProviders
  }
}
let _app_configuration = null;

const loadAppConfiguration = async () => {
  try {
    const response = await fetch(IPNS_URL() + EnvHelper.env.REACT_APP_CONFIGURATION_IPNS_KEY.replace('ipns://', '') + "/" + APP_CONFIGURATION_FILE,
      { method: 'GET', headers: { "Content-Type": "application/text" } })

    if (response.status === 200) {
      const configData = await response.text();
      if (configData) {
        _app_configuration = overrideAppConfig(JSON.parse(configData));
      }
    }
  } catch (err) {
    LogToConsoleError("load configuration failed", err);
  }
}

const NewForwarderCompatibility: { [x: number]: boolean } = {
  [NetworkId.ARBITRUM_SEPOLIA]: true,
  [NetworkId.ETHEREUM_SEPOLIA]: true,
  [NetworkId.ARBITRUM_NOVA_FORK]: false,
  [NetworkId.ARBITRUM_ONE]: true,
  [NetworkId.ARBITRUM_NOVA]: false,
  [NetworkId.MAINNET]: false,
  [NetworkId.POLYGON_MAINNET]: false
}

// @ts-ignore
const GasMultiplier: Record<NetworkId, number> = {
  // [NetworkId.INVALID_CHAIN]: 1,
  [NetworkId.ARBITRUM_SEPOLIA]: 1.25,
  [NetworkId.ETHEREUM_SEPOLIA]: 1,
  [NetworkId.ARBITRUM_NOVA_FORK]: 1,
  [NetworkId.ARBITRUM_ONE]: 1.25,
  [NetworkId.ARBITRUM_NOVA]: 1,
  [NetworkId.MAINNET]: 1,
  [NetworkId.POLYGON_MAINNET]: 1,
};

export const getGasMultiplier = (networkid: NetworkId): number => {
  if (_app_configuration?.GasMultiplier) {
    return (
      _app_configuration?.GasMultiplier?.[networkid] ??
      GasMultiplier?.[networkid] ??
      1
    );
  } else {
    return GasMultiplier?.[networkid] ?? 1;
  }
};

export const checkNewForwarderCompatibility = (
  networkid: NetworkId | ''
): boolean => {
  if (!Boolean(addresses?.[networkid]?.Loot8Forwarder)) {
    return false
  }

  if (_app_configuration?.NewForwarderCompatibility) {
    return (
      _app_configuration?.NewForwarderCompatibility?.[networkid] ??
      NewForwarderCompatibility[networkid] ??
      false
    )
  } else {
    return NewForwarderCompatibility[networkid] ?? false
  }
}

interface ISubgraphConfigModules {
  user: boolean;
  userOwnedCollections: boolean;
  whitelistedCollections: boolean;
  entity: boolean;
  listTokenOwner: boolean;
  collectibleDetails: boolean;
  marketplaceListing: boolean;
  events: boolean;
};

interface ISubgraphConfig {
  endpoints: { [key in NetworkId]?: string };
  modules: ISubgraphConfigModules;
};

const subgraphs = {
  '1': '',
  '137': '',
  '42161': 'https://graph-node-01.loot8-services.dev:8000/subgraphs/name/loot8',
  '42170': '',
  '421614': 'https://ipfs-dev-02.loot8-services.dev:8000/subgraphs/name/loot8',
  '11155111': 'https://ipfs-dev-02.loot8-services.dev:8000/subgraphs/name/loot8-eth-sepolia',
  '42170999': '',
};

const getSubgraphConfigModules = (
  appconfig: any,
): ISubgraphConfig['modules'] => {
  return {
    user: appconfig?.subgraphService?.modules?.user ?? true,
    userOwnedCollections: appconfig?.subgraphService?.modules?.userOwnedCollections ?? true,
    whitelistedCollections: appconfig?.subgraphService?.modules?.whitelistedCollections ?? true,
    entity: appconfig?.subgraphService?.modules?.entity ?? true,
    listTokenOwner: appconfig?.subgraphService?.modules?.listTokenOwner ?? true,
    collectibleDetails: appconfig?.subgraphService?.modules?.collectibleDetails ?? true,
    marketplaceListing: appconfig?.subgraphService?.modules?.marketplaceListing ?? true,
    events: appconfig?.subgraphService?.modules?.events ?? true,
  };
};

const getParsedSubgraphConfig = (appconfig: any, chainIds: number[]) => {
  const modules = getSubgraphConfigModules(appconfig);
  const endpoints: ISubgraphConfig['endpoints'] = {};

  chainIds.forEach(chainId => {
    endpoints[chainId] = appconfig?.subgraphService?.endpoints?.[chainId] || subgraphs[chainId] || '';
  });

  return { endpoints, modules };
};

export const getSubgraphConfig = async () => {
  let appconfig: any;

  try {
    appconfig = await getAppConfiguration();
  } catch (error) {
    LogToConsole(
      'getSubgraphConfig | Error while reading app config, fallback triggered',
    );
  }

  let config: ISubgraphConfig = {
    endpoints: {},
    modules: getSubgraphConfigModules(undefined),
  };

  //* Provide the Config from the IPNS App Config
  switch (EnvHelper.env.REACT_APP_ENV) {
    case "development": {
      config = getParsedSubgraphConfig(appconfig, [
        NetworkId.ARBITRUM_SEPOLIA,
        NetworkId.ETHEREUM_SEPOLIA
      ])
      break;
    }
    case "staging": {
      config = getParsedSubgraphConfig(appconfig, [
        NetworkId.ARBITRUM_NOVA_FORK,
      ]);
      break;
    }
    case "production": {
      config = getParsedSubgraphConfig(appconfig, [
        NetworkId.MAINNET,
        NetworkId.ARBITRUM_ONE,
        NetworkId.ARBITRUM_NOVA,
        NetworkId.POLYGON_MAINNET,
      ]);
      break;
    }
    default: {
      config = getParsedSubgraphConfig(appconfig, [NetworkId.ARBITRUM_SEPOLIA]);
      break;
    }
  }

  return config;
};

// Calculate Deadline used by LootForwarder - Now + 5 minutes
export const META_TX_DEADLINE = (): number => {
  // Deadline used by LootForwarder while executing a MetaTx - 5 minutes
  let time = _app_configuration?.META_TX_DEADLINE_IN_SECONDS
    ? _app_configuration?.META_TX_DEADLINE_IN_SECONDS
    : 5 * 60;

  return Math.round(Date.now() / 1000) + time;
};

export const getAppConfiguration = async () => {
  try {
    if (_app_configuration) {
      return _app_configuration;
    } else {
      initAppConfig();
      await loadAppConfiguration();
      return _app_configuration;
    }
  } catch (e) {
    LogToConsoleError("getAppConfiguration - app configuration failed", e);
  } finally {
    return _app_configuration;
  }
}

// initialize app configuration.
const overrideAppConfig = (config): any => {
  try {
    // get relayer detail from config
    if (config && config?.relayerInfo && config?.relayerInfo.length > 0) {
      _relayerInfo = config?.relayerInfo
    } else {
      _relayerInfo = null;
    }

  } catch (error) {
    LogToConsoleError("initializeAppConfiguration:", error);
  }

  return config;
}

const getURI = (networkid: number) => {
  if (_app_configuration?.nodeProviders && _app_configuration?.nodeProviders[networkid]) {

    return _app_configuration?.nodeProviders[networkid];
  } else {
    return nodeProviders[networkid];
  }
}

const getLogsProviderURI = (networkid: number) => {
  if (_app_configuration?.logsNodeProviders && _app_configuration?.logsNodeProviders[networkid]) {
    return _app_configuration?.logsNodeProviders[networkid];
  } else {
    return logsNodeProviders[networkid];
  }
}

export const getNetwork = (): NetworkId => {
  return Number(EnvHelper.env.REACT_APP_DEFAULTNETWORK);
}
export const getNetworkName = () => {
  return NETWORKS[getNetwork()].chainName;
}
export const getNetworkRPC = () => {
  return NETWORKS[getNetwork()].rpcUrls[0];
}
export const getNetworkURI = () => {
  return NETWORKS[getNetwork()].uri();
}

let _staticProvider = {};
export const getStaticProvider = () => {
  const uri = NETWORKS[getNetwork()].uri();
  if (!_staticProvider[uri]) {
    _staticProvider[uri] = new StaticJsonRpcProvider(uri);
  }
  return _staticProvider[uri];
}

let _anynetStaticProvider = {};
export const getAnynetStaticProvider = (chainId: NetworkId | "") => {
  if (chainId === "") return null;
  if (!_anynetStaticProvider[chainId]) {
    _anynetStaticProvider[chainId] = new StaticJsonRpcProvider(NETWORKS[chainId].uri());
  }
  return _anynetStaticProvider[chainId];
};

let _staticLogsProvider = {};

export const getStaticLogsProvider = (chainId: NetworkId | "") => {
  if (chainId === "") return null;
  const uri = getLogsProviderURI(chainId);
  if (!_staticLogsProvider[chainId]) {
    _staticLogsProvider[chainId] = new StaticJsonRpcProvider(uri);
  }
  return _staticLogsProvider[chainId];
}

export const getNetworkID = (chain: number) => {
  let selectedNetworkID: NetworkId;
  switch (chain) {
    case 80001:
      selectedNetworkID = NetworkId.POLYGON_MUMBAI_TESTNET;
      break;
    case 137:
      selectedNetworkID = NetworkId.POLYGON_MAINNET;
      break;
    case 42161:
      selectedNetworkID = NetworkId.ARBITRUM_ONE;
      break;
    case 338:
      selectedNetworkID = NetworkId.CRONOS_TESTNET;
      break;
    case 25:
      selectedNetworkID = NetworkId.CRONOS_MAINNET;
      break;
    case 5:
      selectedNetworkID = NetworkId.GOERLI;
      break;
    case 1:
      selectedNetworkID = NetworkId.MAINNET;
      break;
    case 719:
      selectedNetworkID = NetworkId.SHIB_TESTNET;
      break;
    case 109:
      selectedNetworkID = NetworkId.SHIB_MAINNET;
      break;
    case 421614:
      selectedNetworkID = NetworkId.ARBITRUM_SEPOLIA;
      break;
    case 11155111:
      selectedNetworkID = NetworkId.ETHEREUM_SEPOLIA;
      break;
    case 42170:
      selectedNetworkID = NetworkId.ARBITRUM_NOVA;
      break;
    case 42170999:
      selectedNetworkID = NetworkId.ARBITRUM_NOVA_FORK;
      break;
    default:
      selectedNetworkID = NetworkId.ARBITRUMTEST;
      break;
  }
  return selectedNetworkID;
}

export const WEBHOOK_URL = (networkId: number | string) => {
  if (networkId && _relayerInfo) {
    const relayer = _relayerInfo?.find((x) => x.networkId === networkId);
    if (relayer && relayer.url) {
      return relayer.url
    }
  }
  return NETWORKS[networkId].WEBHOOK_URL;
}

export const getGenesisBlockNumber = (networkId: number): number => {
  return NETWORKS[networkId]?.genesisBlockNumber ?? 0;
}

export const NETWORKS: { [key: number]: INetwork } = {
  [NetworkId.ARBITRUM_ONE]: {
    chainName: "Arbitrum",
    chainId: NetworkId.ARBITRUM_ONE,
    nativeCurrency: {
      name: "Arbitrum",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://app.loot8.io/rpc/42161/fmyholol0tax4pugrprbk3dacb2nmdav"],
    blockExplorerUrl: "https://arbiscan.io/",
    image: arbitrum_logo,
    imageAltText: "Arbitrum Mainnet",
    uri: () => getURI(NetworkId.ARBITRUM_ONE),
    isOracleIntegrated: true,
    WEBHOOK_URL: "https://loot8-relayer.loot8-services.dev/webhook/1211cbcc-aec4-4787-979b-048a49339f40",
    genesisBlockNumber: 70095541
  },
  [NetworkId.ARBITRUMTEST]: {
    chainName: "Arbitrum Testnet",
    chainId: NetworkId.ARBITRUMTEST,
    nativeCurrency: {
      name: "Arbitrum",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://goerli-rollup.arbitrum.io/rpc", "https://arbitrum-goerli.infura.io/v3"],
    blockExplorerUrl: "https://goerli.arbiscan.io/",
    image: arbitrum_logo,
    imageAltText: "Arbitrum Testnet",
    uri: () => getURI(NetworkId.ARBITRUMTEST),
    isOracleIntegrated: true,
    WEBHOOK_URL: "https://loot8-relayer-qa.loot8-services.dev/webhook/84779292-73b7-4d1b-a561-97546d7a4798",
    genesisBlockNumber: 11000000

  },
  [NetworkId.POLYGON_MUMBAI_TESTNET]: {
    chainName: "Polygon Testnet",
    chainId: NetworkId.POLYGON_MUMBAI_TESTNET,
    nativeCurrency: {
      name: "Polygon",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://app.loot8.io/rpc/80001/f3w21nwivc6b2b388lcaatyzhkk9sd61"],
    blockExplorerUrl: "https://mumbai.polygonscan.com/",
    image: polygon_matic,
    imageAltText: "Polygon Testnet",
    uri: () => getURI(NetworkId.POLYGON_MUMBAI_TESTNET),
    isOracleIntegrated: true,
    WEBHOOK_URL: "https://loot8-relayer-qa.loot8-services.dev/webhook/eeb6dd2a-3211-446a-96a8-a37873be2c7e",
    genesisBlockNumber: 30000000

  },
  [NetworkId.POLYGON_MAINNET]: {
    chainName: "Polygon Mainnet",
    chainId: NetworkId.POLYGON_MAINNET,
    nativeCurrency: {
      name: "Polygon",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://app.loot8.io/rpc/137/fmyholol0tax4pugrprbk3dacb2nmdav"],
    blockExplorerUrl: "https://polygonscan.com/",
    image: polygon_matic,
    imageAltText: "Polygon Mainnet",
    uri: () => getURI(NetworkId.POLYGON_MAINNET),
    isOracleIntegrated: true,
    WEBHOOK_URL: "https://loot8-relayer.loot8-services.dev/webhook/b7871c16-35e7-4171-b538-63c2cb66f025",
    genesisBlockNumber: 38000000

  },
  [NetworkId.CRONOS_TESTNET]: {
    chainName: "Cronos Testnet",
    chainId: NetworkId.CRONOS_TESTNET,
    nativeCurrency: {
      name: "Cronos",
      symbol: "TCRO",
      decimals: 18,
    },
    rpcUrls: ["https://evm-t3.cronos.org/"],
    blockExplorerUrl: "https://explorer.cronos.org/testnet/",
    image: null,
    imageAltText: "Cronos Testnet",
    uri: () => getURI(NetworkId.CRONOS_TESTNET),
    WEBHOOK_URL: "",
    genesisBlockNumber: -1500

  },
  [NetworkId.CRONOS_MAINNET]: {
    chainName: "Cronos Mainnet",
    chainId: NetworkId.CRONOS_MAINNET,
    nativeCurrency: {
      name: "Cronos",
      symbol: "CRO",
      decimals: 18,
    },
    rpcUrls: ["https://evm.cronos.org/"],
    blockExplorerUrl: "https://cronos.org/explorer/",
    image: null,
    imageAltText: "Cronos Mainnet",
    uri: () => getURI(NetworkId.CRONOS_MAINNET),
    WEBHOOK_URL: "",
    genesisBlockNumber: -7000

  },
  [NetworkId.GOERLI]: {
    chainName: "Goerli Testnet",
    chainId: NetworkId.GOERLI,
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://goerli.infura.io/v3"],
    blockExplorerUrl: "https://goerli.etherscan.io/",
    image: null,
    imageAltText: "Goerli Testnet",
    uri: () => getURI(NetworkId.GOERLI),
    WEBHOOK_URL: "",
    genesisBlockNumber: 9349341

  },
  [NetworkId.MAINNET]: {
    chainName: "ETH Mainnet",
    chainId: NetworkId.MAINNET,
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://app.loot8.io/rpc/1/fmyholol0tax4pugrprbk3dacb2nmdav"],
    blockExplorerUrl: "https://etherscan.io/",
    image: null,
    imageAltText: "Eth Mainnet",
    uri: () => getURI(NetworkId.MAINNET),
    WEBHOOK_URL: "",
    genesisBlockNumber: 17666174

  },
  [NetworkId.SHIB_TESTNET]: {
    chainName: "Shibarium Puppynnet",
    chainId: NetworkId.SHIB_TESTNET,
    nativeCurrency: {
      name: "BONE",
      symbol: "BONE",
      decimals: 18,
    },
    rpcUrls: ["https://puppynet.shibrpc.com"],
    blockExplorerUrl: "https://puppyscan.shib.io/",
    image: null,
    imageAltText: "Shibarium Testnet",
    uri: () => getURI(NetworkId.SHIB_TESTNET),
    WEBHOOK_URL: "",
    genesisBlockNumber: 0

  },
  [NetworkId.SHIB_MAINNET]: {
    chainName: "Shibarium Mainnet",
    chainId: NetworkId.SHIB_MAINNET,
    nativeCurrency: {
      name: "BONE",
      symbol: "BONE",
      decimals: 18,
    },
    rpcUrls: ["https://www.shibrpc.com"],
    blockExplorerUrl: "https://shibariumscan.io",
    image: null,
    imageAltText: "Shibarium Mainnet",
    uri: () => getURI(NetworkId.SHIB_MAINNET),
    WEBHOOK_URL: "",
    genesisBlockNumber: 0

  },
  [NetworkId.ARBITRUM_SEPOLIA]: {
    chainName: "Arbitrum Sepolia",
    chainId: NetworkId.ARBITRUM_SEPOLIA,
    nativeCurrency: {
      name: "Sepolia",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://app.loot8.io/rpc/421614/f3w21nwivc6b2b388lcaatyzhkk9sd61"],
    blockExplorerUrl: "https://sepolia.arbiscan.io/",
    image: arbitrum_logo,
    imageAltText: "Arbitrum Sepolia",
    uri: () => getURI(NetworkId.ARBITRUM_SEPOLIA),
    WEBHOOK_URL: "https://loot8-relayer-dev.loot8-services.dev/webhook/aaae77ec-0fee-4075-9335-bc1010129632",
    genesisBlockNumber: 586837
  },
  [NetworkId.ETHEREUM_SEPOLIA]: {
    chainName: "Ethereum Sepolia",
    chainId: NetworkId.ETHEREUM_SEPOLIA,
    nativeCurrency: {
      name: "Sepolia Ether",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ['https://app.loot8.io/rpc/11155111/f3w21nwivc6b2b388lcaatyzhkk9sd61'],
    blockExplorerUrl: "https://sepolia.etherscan.io/",
    image: ethereum_logo,
    imageAltText: "Ethereum Sepolia",
    uri: () => getURI(NetworkId.ETHEREUM_SEPOLIA),
    WEBHOOK_URL: 'https://loot8-relayer-qa.loot8-services.dev/webhook/f690f9fd-4801-4141-b552-c58853a2121a',
    genesisBlockNumber: 600000,
  },
  [NetworkId.ARBITRUM_NOVA]: {
    chainName: "Arbitrum Nova",
    chainId: NetworkId.ARBITRUM_NOVA,
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://app.loot8.io/rpc/42170/fmyholol0tax4pugrprbk3dacb2nmdav"],
    blockExplorerUrl: "https://nova.arbiscan.io/",
    image: arbitrum_logo,
    imageAltText: "Arbitrum Nova",
    uri: () => getURI(NetworkId.ARBITRUM_NOVA),
    WEBHOOK_URL: "https://loot8-relayer.loot8-services.dev/webhook/0e7a2121-ab76-4a32-b01d-ed98788be7f3",
    genesisBlockNumber: 27000000

  },
  [NetworkId.ARBITRUM_NOVA_FORK]: {
    chainName: "Arbitrum Nova Fork",
    chainId: NetworkId.ARBITRUM_NOVA_FORK,
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://app.loot8.io/rpc/staging/42170999/femvikz02ltxdhbw78oclxt2rbgvx5by"],
    blockExplorerUrl: "https://nova.arbiscan.io/",
    image: arbitrum_logo,
    imageAltText: "Arbitrum Nova",
    uri: () => getURI(NetworkId.ARBITRUM_NOVA_FORK),
    WEBHOOK_URL: "https://loot8-relayer-qa.loot8-services.dev/webhook/257933aa-84ee-4de4-8007-231e4d38f8eb",
    genesisBlockNumber: 27000000

  }
};

// app storage key
export const APP_STORAGE_USER_KEY = '@user_key';
// export const APP_STORAGE_GET_COLLECTIBLEDETAILS = (collectibleAddress) => { return '@collectible_' + collectibleAddress.toLowerCase() };

export const initialBlock = 3607057;

let nodeProviders = {
  "421613": EnvHelper.env.REACT_APP_NODE_PROVIDER_421613,
  "42161": EnvHelper.env.REACT_APP_NODE_PROVIDER_42161,
  "80001": EnvHelper.env.REACT_APP_NODE_PROVIDER_80001,
  "137": EnvHelper.env.REACT_APP_NODE_PROVIDER_137,
  "338": EnvHelper.env.REACT_APP_NODE_PROVIDER_338,
  "25": EnvHelper.env.REACT_APP_NODE_PROVIDER_25,
  "5": EnvHelper.env.REACT_APP_NODE_PROVIDER_5,
  "1": EnvHelper.env.REACT_APP_NODE_PROVIDER_1,
  "719": EnvHelper.env.REACT_APP_NODE_PROVIDER_719,
  "109": EnvHelper.env.REACT_APP_NODE_PROVIDER_109,
  "421614": EnvHelper.env.REACT_APP_NODE_PROVIDER_421614,
  "11155111": EnvHelper.env.REACT_APP_NODE_PROVIDER_11155111,
  "42170999": EnvHelper.env.REACT_APP_NODE_PROVIDER_42170999,
  "42170": EnvHelper.env.REACT_APP_NODE_PROVIDER_42170,
};

let logsNodeProviders = {
  "421613": EnvHelper.env.REACT_APP_LOGS_NODE_PROVIDER_421613,
  "42161": EnvHelper.env.REACT_APP_LOGS_NODE_PROVIDER_42161,
  "80001": EnvHelper.env.REACT_APP_LOGS_NODE_PROVIDER_80001,
  "137": EnvHelper.env.REACT_APP_LOGS_NODE_PROVIDER_137,
  "338": EnvHelper.env.REACT_APPLOGS__NODE_PROVIDER_338,
  "25": EnvHelper.env.REACT_APP_LOGS_NODE_PROVIDER_25,
  "5": EnvHelper.env.REACT_APP_LOGS_NODE_PROVIDER_5,
  "1": EnvHelper.env.REACT_APP_LOGS_NODE_PROVIDER_1,
  "719": EnvHelper.env.REACT_APP_LOGS_NODE_PROVIDER_719,
  "109": EnvHelper.env.REACT_APP_NODE_PROVIDER_109,
  "421614": EnvHelper.env.REACT_APP_NODE_PROVIDER_421614,
  "11155111": EnvHelper.env.REACT_APP_LOGS_NODE_PROVIDER_11155111,
  "42170999": EnvHelper.env.REACT_APP_LOGS_NODE_PROVIDER_42170999,
  "42170": EnvHelper.env.REACT_APP_NODE_PROVIDER_42170,
};

// App storage Id
export const APP_STORAGE_ENTITY_LIST = '@allentitylist';

export const APP_STORAGE_PHYGITAL_LIST = (entity, networkID) => '@allPhygitalist_' + entity.toLowerCase() + networkID.toString();


export const APP_STORAGE_GET_COLLECTIBLEDETAILS = (collectibleAddress) => { return '@collectible_' + collectibleAddress.toLowerCase() };
export const APP_STORAGE_GET_EXTERNAL_COLLECTIBLEDETAILS = (collectibleAddress) => { return '@external_collectible_' + collectibleAddress.toLowerCase() };

export const APP_STORAGE_GET_ENTITYDETAILS = (entityAddress) => { return '@entity_' + entityAddress.toLowerCase() };
export const APP_STORAGE_GET_ENTITY_REFRESH_TIME = (entityAddress) => { return '@entity_refreshExecTime_' + entityAddress.toLowerCase() };

export const POAP_LAST_FETCH_BLOCK = (entity, networkID) => '@loot8-poap-lastFetchTimestamp_' + entity.toLowerCase() + networkID.toString();
export const APP_STORAGE_EXPASS_MINT_BLOCK = (entity) => '@loot8-expasses-mint-lastFetchTimestamp_' + entity.toLowerCase();
export const APP_STORAGE_EXPASS_MINTS = (entity) => '@loot8-expasses-mints_' + entity.toLowerCase();







import { SidebarComponent } from "../../components/Sidebar/SidebarComponent";
import { Dashboard } from "../Dashboard/Dashboard";
import { CollectionDetails } from "../CollectionDetails/CollectionDetails";
import "./UserPanel.scss"
import { ThirdpartyCollectionDetails } from "../ThirdpartyCollection/ThirdpartyCollectionDetails";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import LoginPage from "../LoginPage/LoginPage";
import { useAppSelector, useWeb3Context } from "../../hooks";
import { useEffect, useMemo, useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import { checkSuperAdmin, loadAllDetails, setAllDataLoading, setSuperAdmin } from "../../slices/EntitySlice";
import { AppDispatch } from "../../store";
import { CatalogTypes } from "../../enums/catalog.enum";
import { AdministrationDetails } from "../Administration/AdministrationDetails";
import { InviteCodeModal } from "../../components/ModalComponents/InviteCodeModal";
import { NavigationRoutes, OnboardingNavigationRoutes } from "../../enums/routes.enum";
import BillBoardsPage from "../BillboardsPage/BillboardsPage";
import { PassportType } from "../../enums/offers.enum";
import OnboardingProfilePage from "../OnboardingProfilePage";
import { getData, storeData } from "../../helpers/AppStorage";
import { APP_STORAGE_GET_ENTITY_REFRESH_TIME, getStaticLogsProvider } from "../../constants";
import { getPassportMintDetails } from "../../slices/PassportSlice";
import { LogToConsole } from "../../helpers/Logger";
import ProfileCreation from "../ProfileCreation/ProfileCreation";
import { LoaderModal } from "../../components/ModalComponents/LoaderModal";
import { EventInvitationModal } from "../../components/ModalComponents/EventInvitationModal";

let refreshCacheExecutionStarted = false;

export const UserPanelView = ({ setLoading }) => {
  const { connected, address , wallet, networkId, staticProvider } = useWeb3Context();
  const location  = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const [ menuOpen, setMenuOpen ] = useState(matches);
  const entityDetails = useAppSelector(state => state.Entity.EntityData);
  const isSuperAdmin = useAppSelector((state) => state.Entity.isSuperAdmin)
  const isUserNew = useAppSelector((state) => state?.AppUser?.isNewUser)
  const passports = useAppSelector(state => state.Passports.AllEntityPassportDetails);
  const passportsLoading = useAppSelector(state => state.Passports.loading);
  const premiumChat = useAppSelector(state => state.Offers.AllEntityPremiumChatDetails);
  const premiumChatLoading = useAppSelector(state => state.Offers.PremiumChatsLoading);
  // const privateChat = useAppSelector(state=>state.privateChats.AllPrivateChats);
  // const privateChatLoading = useAppSelector(state=>state.privateChats.privateChatLoading);
  // const regularOffers = useAppSelector(state => state.Offers.AllEntityRegularOfferDetails);
  const offersLoading = useAppSelector(state => state.Offers.Offersloading);
  const featuersOffers = useAppSelector(state => state.Offers.AllEntityFeturedOfferDetails);
  const events = useAppSelector(state => state.Events.AllEntityEventsDetails);
  const eventsLoading = useAppSelector(state => state.Events.Eventsloading);
  const collectibles = useAppSelector(state => state.Offers.AllEntityDigitalCollectiblesDetails)
      ?.filter(obj => (!obj?.animationUrl || obj?.animationUrl === ""));
  const collectiblesLoading = useAppSelector(state => state.Offers.DigitalCollectibleloading);
  const poaps = useAppSelector(state => state.Collectibles.AllPOAPCollectibleDetails);
  const poapsLoading = useAppSelector(state => state.Collectibles.poapsLoading);
  const [ inviteCodeModalOpen, setInviteCodeModalOpen ] = useState(false);
  const [run, setRun] = useState<boolean>(false);
  const metaTxnLoading = useAppSelector(state => state.App.msgLoading);
  const [freeProfileLoading,setFreeProfileLoading] = useState({open:false,message:""});
  const [isInviteModal,setIsInviteModal] = useState<boolean>(false);  
    
  // handle collapse of side bar
  const handleToggle = () => {
    setMenuOpen(!menuOpen  && matches);
  }

  const isLoading = useMemo(()=>{
    return Boolean(
       (!isSuperAdmin && (passportsLoading||premiumChatLoading ||eventsLoading ||offersLoading||collectiblesLoading || poapsLoading)) || 
       (isSuperAdmin &&( passportsLoading||eventsLoading||collectiblesLoading||poapsLoading||offersLoading||premiumChatLoading)))
  },[passportsLoading,eventsLoading,collectiblesLoading,poapsLoading,offersLoading,premiumChatLoading,isSuperAdmin])

  

  // useEffect(()=>{
  //   if(!isLoading &&  passports?.length && entityDetails?.entityOnboarded){
  //     const isFreePassportAvailable = passports?.find(i=>!i.isPremium && i.isActive);
  //     console.log({isFreePassportAvailable});console.log({isLoading});
  //     if(!isFreePassportAvailable && !isLoading){
  //       console.log('hello');
  //       window.location.replace("/profile-creation")
  //     }
  //   }
  // },[passports,entityDetails?.entityOnboarded,isLoading])
  
  useEffect(() => {
    setMenuOpen(matches);
  },[matches]);

  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
      // Fetch latest details
      const loadDetails = (async () => {
          if(connected && wallet) {
            refreshCacheExecutionStarted = true;
            await dispatch(setAllDataLoading()).then(async () => {
              const result = await checkSuperAdmin({networkID:networkId,provider:staticProvider,walletAddress:wallet?.address,entityAddress:entityDetails?.address})
              dispatch(setSuperAdmin(result))
              await dispatch(loadAllDetails({ networkID: networkId, provider: staticProvider, address, wallet: wallet, isCache: true,isSuperAdmin:result })).then(() => 
                {
                 refreshCacheExecutionStarted = false;
               });
            
              });

              if (passports?.length == 0 && !passportsLoading && isUserNew) {
                setRun(true)
              }

              await dispatch(getPassportMintDetails({ wallet, networkID: networkId, provider: getStaticLogsProvider(networkId), entity: entityDetails.address, isCache: true}));
          }
      });
      loadDetails();

      // refresh latest details
      const refreshCachedData = async () => {
        
        let isCacheExpired = true;
        const lastExecutionTime = await getData(APP_STORAGE_GET_ENTITY_REFRESH_TIME(entityDetails.address));
        if (lastExecutionTime) {
          if ((Date.now() - Number(lastExecutionTime)) < (30 * 60 * 1000)) {
            isCacheExpired = false;
          }
        }
        if(connected && wallet && isCacheExpired && !refreshCacheExecutionStarted && !metaTxnLoading) {
            LogToConsole("Refreshing Cached Data")
            refreshCacheExecutionStarted = true
            await dispatch(loadAllDetails({ networkID: networkId, provider: staticProvider, address, wallet: wallet, isCache: false })).then(() =>
            {
              refreshCacheExecutionStarted = false;
            });

            await storeData(APP_STORAGE_GET_ENTITY_REFRESH_TIME(entityDetails.address), Date.now());
        }
      }
      setInterval(() => { refreshCachedData(); }, 5 * 60 * 1000);

      return (() => {
        refreshCacheExecutionStarted = false;
      })
  }, [connected]);

  
  
  return (
    <div className="d-flex mainBox">
      {location.pathname!="/profile-creation" ? 
      <SidebarComponent isLoading={isLoading} menuOpen={menuOpen} handleToggle={handleToggle} setInviteCodeOpen={setInviteCodeModalOpen}/> : <></>
      }
      <div className="main-body" style={{   maxWidth: location.pathname!="/profile-creation" ? "100vw" : menuOpen ? 'calc(100vw - 15rem)' : 'calc(100vw - 5rem)' }}>
      { freeProfileLoading.open && <LoaderModal open={true} message={freeProfileLoading.message}/> }
      {!isSuperAdmin ?
        <Switch>
          {!connected && <Route path="/login" exact children={() => <LoginPage setLoading={setLoading}/>}/>}
          <Route path={NavigationRoutes.DASHBOARD} exact children={() => <Dashboard isLoading={isLoading}/>}/>
          <Route path={NavigationRoutes.EXPASSES} exact children={() => <CollectionDetails wallet={wallet}  run={run} setRun={setRun} type={CatalogTypes.EXPASSES} collectionData={passports?.filter(obj => obj.passportType === PassportType.SUBSCRIPTON) ?? []} collectionsLoading={passportsLoading}  />}/>
          <Route path={NavigationRoutes.BILLBOARDS} exact children={() => <BillBoardsPage/>}/>
          <Route path={NavigationRoutes.PREMIUM_CHAT} exact children={() => <CollectionDetails wallet={wallet}  type={CatalogTypes.PREMIUM_CHAT} collectionData={premiumChat} collectionsLoading={premiumChatLoading}  />}/>
          <Route path={NavigationRoutes.PROFILE} exact component={() => <OnboardingProfilePage isOnboarding={false}/>}/>
          <Route path={NavigationRoutes.PROFILE_CREATION} exact component={() => 
          <ProfileCreation connected={connected} 
          address={address}  
          wallet={wallet} 
          networkId={networkId} staticProvider={staticProvider} 
          setFreeProfileLoading={setFreeProfileLoading}
          />}/>
          
          <Route path={NavigationRoutes.POAPS} exact children={() => <CollectionDetails type={CatalogTypes.EXTERNAL} collectionData={poaps} collectionsLoading={poapsLoading}/>}/>

          <Route path={NavigationRoutes.COLLECTIBLES} exact children={() => <CollectionDetails wallet={wallet} run={run} setRun={setRun} type={CatalogTypes.COLLECTIBLES} collectionData={collectibles} collectionsLoading={collectiblesLoading}/>}/>
          <Route path={NavigationRoutes.FEATUREDOFFER} exact children={() => <CollectionDetails wallet={wallet} type={CatalogTypes.FEATUREDOFFERS} collectionData={featuersOffers} collectionsLoading={offersLoading} />}/>
          <Route path={NavigationRoutes.EVENTS} exact children={() => <CollectionDetails wallet={wallet} type={CatalogTypes.EVENT} collectionData={events} collectionsLoading={eventsLoading} />}/>

          <Route path={["/", "*"]} component={() => connected ? <Redirect to={ Object.values(OnboardingNavigationRoutes).includes(location.pathname as OnboardingNavigationRoutes) ? location.pathname : NavigationRoutes.DASHBOARD} /> : <Redirect to="/login" /> }/>
        </Switch>
        :
        <Switch>
          {!connected && <Route path="/login" exact children={() => <LoginPage setLoading={setLoading}/> }/> }
          <Route path={NavigationRoutes.DASHBOARD} exact children={() => <Dashboard isLoading={isLoading}/>}/>
          {/* <Route path={NavigationRoutes.PASSPORT} exact children={() => <CollectionDetails type={CatalogTypes.EXPASSES} collectionData={passports?.filter(obj => obj.passportType === PassportType.REGULAR) ?? []} collectionsLoading={passportsLoading} />}/> */}
          <Route path={NavigationRoutes.EXPASSES} exact children={() => <CollectionDetails wallet={wallet}  run={run} setRun={setRun} type={CatalogTypes.EXPASSES} collectionData={passports?.filter(obj => obj.passportType === PassportType.SUBSCRIPTON) ?? []} collectionsLoading={passportsLoading} />}/>
          <Route path={NavigationRoutes.FEATUREDOFFER} exact children={() => <CollectionDetails wallet={wallet}  type={CatalogTypes.FEATUREDOFFERS} collectionData={featuersOffers} collectionsLoading={offersLoading} />}/>
          {/* <Route path={NavigationRoutes.OFFERS} exact children={() => <CollectionDetails type={CatalogTypes.OFFERS} collectionData={regularOffers} collectionsLoading={offersLoading} />}/> */}
          <Route path={NavigationRoutes.EVENTS} exact children={() => <CollectionDetails wallet={wallet} type={CatalogTypes.EVENT} collectionData={events} collectionsLoading={eventsLoading} />}/>
          <Route path={NavigationRoutes.COLLECTIBLES} exact children={() => <CollectionDetails wallet={wallet}  run={run} setRun={setRun} type={CatalogTypes.COLLECTIBLES} collectionData={collectibles} collectionsLoading={collectiblesLoading}/>}/>
          <Route path={NavigationRoutes.POAPS} exact children={() => <CollectionDetails wallet={wallet}  type={CatalogTypes.EXTERNAL} collectionData={poaps} collectionsLoading={poapsLoading}/>}/>
          <Route path={NavigationRoutes.THIRDPARTYCOLLECTION} exact children={() => <ThirdpartyCollectionDetails/>}/>
          <Route path={NavigationRoutes.SOCIALMEDIA} exact children={() => <AdministrationDetails/>}/>
          <Route path={NavigationRoutes.BILLBOARDS} exact children={() => <BillBoardsPage/>}/>
          <Route path={NavigationRoutes.PREMIUM_CHAT} exact children={() => <CollectionDetails wallet={wallet}  type={CatalogTypes.PREMIUM_CHAT} collectionData={premiumChat} collectionsLoading={premiumChatLoading} />}/>
           {/* <Route path={NavigationRoutes.PRIVATE_CHAT} exact children={() => <CollectionDetails type={CatalogTypes.PRIVATE_CHAT} collectionData={privateChat} collectionsLoading={privateChatLoading} />}/>  */}
          {/* <Route path={NavigationRoutes.SETTINGS} exact children={() => <SettingsPage/>}/> */}
          <Route path={NavigationRoutes.PROFILE} exact component={() => <OnboardingProfilePage isOnboarding={false}/>}/>

          <Route path={["/", "*"]} children={ () => connected ? <Redirect to={ Object.values(NavigationRoutes).includes(location.pathname as NavigationRoutes) ? location.pathname : NavigationRoutes.DASHBOARD} /> : <Redirect to="/login" /> }/>
        </Switch>
      }
   
      {/* <EventInvitationModal /> */}
      { entityDetails?.entityOnboarded && inviteCodeModalOpen && <InviteCodeModal modalOpen={inviteCodeModalOpen} setModalOpen={setInviteCodeModalOpen}/> }
      </div>
    </div>
  );
}